import { useState } from "react";
import "./App.css";
import logo from "./logo.svg";

export const DEEP_LINKING_PATHS = [
  { path: "aluguel-de-carros", label: "Aluguel de Carros" },
  { path: "meus-carros", label: "Meus Carros" },
  { path: "clube/home", label: "Clube Home" },
  { path: "clube/home-planos", label: "Planos do Clube" },
  { path: "clube/home-beneficios", label: "Benefícios do Clube" },
  { path: "clube/regularizar", label: "Regularizar Clube" },
  { path: "meus-voos", label: "Meus Voos" },
  { path: "sobre", label: "Sobre Smiles" },
  { path: "atendimento", label: "Atendimento" },
  { path: "extrato", label: "Extrato" },
  { path: "comprar-milhas", label: "Comprar Milhas" },
  { path: "passageiros-favoritos", label: "Passageiros Favoritos" },
  { path: "meus-cartoes", label: "Meus Cartões" },
  { path: "meu-uber", label: "Meu Uber" },
  { path: "notificacoes", label: "Notificações" },
  { path: "parceiras", label: "Parceiras" },
  { path: "preferencias", label: "Preferências" },
  { path: "perfil", label: "Perfil" },
  { path: "promocoes", label: "Promoções" },
  { path: "quiz", label: "Quiz" },
  { path: "reativar-milhas", label: "Reativar Milhas" },
  { path: "solicitar-milhas", label: "Solicitar Milhas" },
  { path: "buscar-hotel", label: "Buscar Hotel" },
  { path: "uber", label: "Uber" },
  { path: "atualizar-cadastro", label: "Atualizar Cadastro" },
  { path: "meus-cartoes-cobranded", label: "Meus Cartões Cobranded" },
  { path: "radar-smiles", label: "Radar Smiles" },
  { path: "emissao", label: "Emissão" },
  { path: "", label: "Home" },
  { path: "minha-categoria", label: "Minha categoria" },
  { path: "indicar-amigos", label: "Indicar amigos" },
];

function App() {
  const [env, setEnv] = useState("hml");

  const handleEnvChange = (e: any) => {
    setEnv(e.target.value);
  };

  const generateLink = (path: any, label: string) => {
    const base = `com.br.smiles${env ? `.${env}` : ""}`;
    console.log(`label: ${label} - ${base}://${path}`, );
    return `${base}://${path}`;
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Deep Linking Example 🚀</p>

        <div className="env-selector">
          <label>Selecionar Ambiente: </label>
          <select
            onChange={handleEnvChange}
            value={env}
            className="styled-select"
          >
            <option value="">Produção</option>
            <option value="hml">Homologação</option>
            <option value="dev">Desenvolvimento</option>
          </select>
        </div>

        <ul>
          {DEEP_LINKING_PATHS.map((link) => (
            <li
              key={link.path}
              style={{ margin: "10px 0", listStyleType: "none" }}
            >
              <a
                href={generateLink(link.path, link.label)}
                target="_blank"
                rel="noopener noreferrer"
                className="App-link"
                style={{
                  textDecoration: "none",
                  color: "#61dafb",
                  fontWeight: "bold",
                }}
              >
                {link.label}
              </a>
            </li>
          ))}
        </ul>
      </header>
    </div>
  );
}

export default App;
